import React from 'react';
import { Router } from '@reach/router';
import { Gatekeeper } from 'q3-admin/src/containers';
import Checkout from '../containers/Checkout';
import CheckoutShipping from '../containers/CheckoutShipping';
import CheckoutShippingOption from '../containers/CheckoutShippingOption';
import CheckoutOptions from '../containers/CheckoutOptions';
import CheckoutConfirm from '../containers/CheckoutConfirm';
import HydrationFix from '../components/HydrationFix';

const CheckoutPage = () => (
  <HydrationFix>
    <Gatekeeper redirectPathOnPublic="/login">
      <Checkout>
        <Router>
          <CheckoutShipping path="/checkout" />
          <CheckoutShippingOption path="/checkout/delivery" />
          <CheckoutOptions
            name="options"
            path="/checkout/options"
          />
          <CheckoutConfirm path="/checkout/confirm" />
        </Router>
      </Checkout>
    </Gatekeeper>
  </HydrationFix>
);

export default CheckoutPage;
