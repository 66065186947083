import React from 'react';
import ShippingQuote from 'gatsby-theme-gentek/src/components/ShippingQuote';
import Tile from 'q3-ui/src/tile';
import CheckoutNavigation from './CheckoutNavigation';

export default () => (
  <Tile
    title="shippingservice"
    subtitle="shippingservice"
    divider
  >
    <ShippingQuote name="shippingservice" disableWatcher>
      <CheckoutNavigation />
    </ShippingQuote>
  </Tile>
);
