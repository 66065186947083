import React from 'react';
import Tile from 'q3-ui/src/tile';
import ShippingSyncTo from 'gatsby-theme-gentek/src/components/ShippingSyncTo';
import CheckoutNavigation from './CheckoutNavigation';

export default () => (
  <Tile title="shipping" subtitle="shippingAddress" divider>
    <ShippingSyncTo
      disableOnPickup={false}
      populateFromBilling
    >
      <CheckoutNavigation />
    </ShippingSyncTo>
  </Tile>
);
