import React from 'react';
import { useTranslation } from 'q3-ui-locale';
import List, { ListItem } from 'q3-ui/src/list';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Store } from 'q3-admin/src/containers';
import Address, { AddressHeader } from 'q3-ui/src/address';
import OrderTotal from 'gatsby-theme-gentek/src/components/OrderTotal';
import LocalShipping from '@material-ui/icons/LocalShipping';
import AttachMoney from '@material-ui/icons/AttachMoney';
import CreditCard from '@material-ui/icons/CreditCard';
import {
  green,
  purple,
  blue,
} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import DrawerTrash from 'q3-ui-cart/src/DrawerTrash';

export default () => {
  const {
    data: {
      seq,
      billing,
      shipping,
      shippingOption,
      paymentOption,
      total,
      status,
      currency,
    },
  } = React.useContext(Store);
  const { t } = useTranslation();

  return (
    <Box
      height="100%"
      p={2}
      style={{ backgroundColor: '#FFF' }}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1" color="secondary">
            Checkout
          </Typography>
        </Grid>
        <Grid item>
          <DrawerTrash />
        </Grid>
      </Grid>
      <Box mb={3}>
        <Typography
          component="h2"
          variant="h5"
          color="secondary"
          gutterBottom
        >
          WO#{seq}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item>
          <Address
            {...billing}
            label={t('labels:billTo')}
          />
        </Grid>
        {shipping && (
          <Grid item>
            <Address
              label={t('labels:shipTo')}
              {...shipping}
            />
          </Grid>
        )}
      </Grid>
      {shippingOption || paymentOption ? (
        <Box mt={2}>
          <AddressHeader
            label={t('labels:options')}
            title={t('descriptions:fulfillment')}
          />
          <List>
            {currency && (
              <ListItem
                title={t('labels:currency')}
                description={currency}
                icon={AttachMoney}
                color={green[500]}
              />
            )}
            {shippingOption && (
              <ListItem
                title={t('labels:shippingservice')}
                description={shippingOption}
                icon={LocalShipping}
                color={blue[500]}
              />
            )}
            {paymentOption && (
              <ListItem
                title={t('labels:paymentOption')}
                description={paymentOption}
                icon={CreditCard}
                color={purple[500]}
              />
            )}
          </List>
        </Box>
      ) : null}
      <OrderTotal total={total} />
      {status === 'Quote' && (
        <Typography
          color="secondary"
          component="em"
          display="block"
        >
          * {t('descriptions:subjectToChanges')}
        </Typography>
      )}
    </Box>
  );
};
