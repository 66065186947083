import React from 'react';

export default () => (
  <span>
    This input requires a specific file template.{' '}
    <a
      download
      href="/ExampleZZ0006Upload.xlsx"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      Click here
    </a>{' '}
    to download a sample.
  </span>
);
