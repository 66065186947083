import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { Page } from 'q3-admin/src/containers';
import Collection from 'q3-admin/src/containers/collection';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { COLLECTIONS } from 'gatsby-theme-gentek/src/components/constants';
import { useLoading } from 'q3-ui-rest';
import CheckoutSummary from './CheckoutSummary';
import OrderNotFound from '../components/OrderNotFound';

const Checkout = ({ children, orderId, onEnter }) => {
  const loading = useLoading();
  const [id, setId] = React.useState();
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setId(
        orderId || localStorage.getItem('GENTEK_ORDER_ID'),
      );
      setReady(true);
    }
  }, [orderId]);

  if (!id && ready) return <OrderNotFound />;
  if (!id) return <LinearProgress />;

  return (
    <>
      {loading && (
        <LinearProgress
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
          }}
        />
      )}
      <Box my={2}>
        <Container maxWidth="xl">
          <Location>
            {({ location }) => (
              <Collection
                id={id}
                disableHeader
                location={location}
                collectionName={
                  COLLECTIONS.ORDERS.COLLECTION_NAME
                }
                resourceName={
                  COLLECTIONS.ORDERS.RESOURCE_NAME
                }
                resourceNameSingular={
                  COLLECTIONS.ORDERS.RESOURCE_NAME_SINGULAR
                }
              >
                <Page
                  location={location}
                  loadingComponent={<CircularProgress />}
                  withSeachbar={false}
                  onEnter={onEnter}
                >
                  {() => (
                    <Grid container spacing={1}>
                      <Grid item md={5} xs={12}>
                        <CheckoutSummary />
                      </Grid>
                      <Grid item md={7} xs={12}>
                        {children}
                      </Grid>
                    </Grid>
                  )}
                </Page>
              </Collection>
            )}
          </Location>
        </Container>
      </Box>
    </>
  );
};

Checkout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Checkout;
