import React from 'react';
import ErrorMessageTemplate from 'q3-ui-assets';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import lostSvg from '../assets/lost.svg';

const OrderNotFound = () => (
  <Box my={4}>
    <Container>
      <ErrorMessageTemplate
        title="orderNotFound"
        description="orderNotFound"
        renderBottom={() => (
          <Button
            variant="contained"
            color="secondary"
            size="large"
          >
            Shop
          </Button>
        )}
      >
        <img src={lostSvg} alt="Order not found graphic" />
      </ErrorMessageTemplate>
    </Container>
  </Box>
);

export default OrderNotFound;
