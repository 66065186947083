import React from 'react';
import { Form, Field } from 'q3-ui-forms/src/builders';
import { connect } from 'q3-admin/src/containers';
import { Grid } from '@material-ui/core';
import OrderLabelHelperText from 'gatsby-theme-gentek/src/components/OrderLabelHelperText';
import PaymentOptionSelect from 'gatsby-theme-gentek/src/components/PaymentOptionSelect';
import Tile from 'q3-ui/src/tile';
import { handleFormData } from 'q3-ui-forms/src/helpers';
import { useTranslation } from 'q3-ui-locale';
import CheckoutNavigation from './CheckoutNavigation';

export default connect(({ onSubmit, data }) => {
  const { t } = useTranslation('labels');
  return (
    <Tile title="options" subtitle="options" divider>
      <Form
        enableSubmit={false}
        onSubmit={handleFormData(onSubmit)}
        initialValues={data}
        keep={[
          'paymentOption',
          'po',
          'endCustomerPo',
          'purchaseOrderPath',
          'zz0006Path',
          'name',
          'customerComment',
          'generalUpload1',
          'generalUpload2',
          'customPackingList',
          'isDropShipping',
        ]}
      >
        <PaymentOptionSelect {...data} xl={6} lg={6} />
        <Field
          label={t('opportunityName')}
          name="name"
          helperText="Optional reference"
          type="text"
          xl={6}
          lg={6}
        />
        <Field
          name="po"
          type="text"
          xl={12}
          lg={12}
          override={() => ({
            required: data.forcePoEnabled,
          })}
        />
        <Field
          name="endCustomerPo"
          xl={12}
          lg={12}
          type="text"
        />
        <Grid item lg={6} md={12}>
          <Field
            required={data.forcePoFileEnabled}
            folder="pos"
            name="purchaseOrderPath"
            type="file"
          />
        </Grid>
        <Grid item lg={6} md={12}>
          <Field
            folder="checkout"
            relativePath="checkout"
            name="customPackingList"
            type="file"
            conditional={['isDropShipping=true']}
            xl={6}
            lg={6}
          />
        </Grid>
        <Grid item lg={6} md={12}>
          <Field
            folder="checkout"
            relativePath="checkout"
            name="generalUpload1"
            type="file"
            xl={6}
            lg={6}
          />
        </Grid>
        <Grid item lg={6} md={12}>
          <Field
            folder="checkout"
            relativePath="checkout"
            name="generalUpload2"
            type="file"
            xl={6}
            lg={6}
          />
        </Grid>
        {data.forceLabellingFile && (
          <Field
            required={data.forceLabellingFile}
            relativePath="checkout"
            folder="checkout"
            saveAs="zz0006"
            name="zz0006Path"
            type="file"
            helperText={<OrderLabelHelperText />}
          />
        )}
        <Field
          name="customerComment"
          type="text"
          multiline
          rows={7}
          xl={12}
          lg={12}
        />
        <CheckoutNavigation />
      </Form>
    </Tile>
  );
});
